@import url('https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Raleway:ital@0;1&display=swap');

$font-family-sans-serif: Raleway,
-apple-system,
BlinkMacSystemFont,
"Segoe UI",
Roboto,
"Helvetica Neue",
Arial,
"Noto Sans",
sans-serif,
"Apple Color Emoji",
"Segoe UI Emoji",
"Segoe UI Symbol",
"Noto Color Emoji" !default;


// http://blog.heraldicart.org/2018/02/07/heraldic-tincture-hexcodes/
$argent: #FFFFFF;
$or: #EFD807;
$gules: #E21313;
$azure: #1E7FCB;
$sable: #000000;
$vert: #149414;
$purpure: #965578;
$orange: #FAA401;

$colors: ("blue": $azure,
  "azure": $azure,
  "purple": $purpure,
  "purpure": $purpure,
  "red":$gules,
  "gules":$gules,
  "orange": $orange,
  "yellow":$or,
  "or":$or,
  "green":$vert,
  "vert":$vert,
  "white":$argent,
  "argent":$argent,
  "black":$sable,
  "sable":$sable,
);

$theme-colors:("azure": $azure,
  "purple": $purpure,
  "purpure": $purpure,
  "gules": $gules,
  "orange": $orange,
  "or": $or,
  "vert": $vert,
  "argent": $argent,
  "sable": $sable,
);

body {
  background-color: $sable !important;
  color: $argent !important;
}

.arms {
  width: 50px;
  height: 60px;
}

;

@for $h from 1 to 6 {
  h#{$h} {
    font-family: $font-family-sans-serif;
  }
}

a {
  color: #eee !important;
}

.item-card {
  width: 15rem;
}

.item-card-contact {
  width: 31.5rem;
}

.card {
  background-color: #222 !important;
}

@each $name,
$value in $colors {
  .card-#{$name} {
    border-color: $value !important;
    color: $value;
  }

  .color-#{$name} {
    color: $value;
  }
}

.icon {
  height: 20px;
  width: 20px;
}

@import '~bootstrap/scss/bootstrap.scss';